<template>
    <div>
		<el-table stripe v-loading="loading" :data="dataList" >
			<el-table-column :label="translate('AUTHORITY.AUTH_ID')" prop="authId" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('AUTHORITY.GROUP_CODE')" prop="groupCode"  :show-overflow-tooltip="true">
				<template slot-scope="scope">{{dictTranslate(scope.row.groupCode, dicts.PERMISSION_GROUP)}}</template>
			</el-table-column>
			<el-table-column :label="translate('AUTHORITY.CODE')" prop="code" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('AUTHORITY.NAME')" prop="name"  :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('AUTHORITY.MENU_ID')" prop="menuId"  :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('AUTHORITY.TYPE')" prop="type" :show-overflow-tooltip="true">
				<template slot-scope="scope">{{dictTranslate(scope.row.type, dicts.PERMISSION_TYPE)}}</template>
			</el-table-column>
			<el-table-column :label="translate('COMMON.CREATE_TIME')" prop="createTime"  :show-overflow-tooltip="true"/>
		</el-table>

		<Pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList"/>

	</div>
</template>

<script>
	import {UserApi} from '@/api';
	export default {
		name: "ToolBar",
		components: {},
		props: {
			dicts: {
				type: Object,
				default: {}
			},
			queryParams: {
				type: Object,
				default: {},
			},
		},
		data() {
			return {
				pageNo: 1,
				pageSize: 10,
				loading: false,
				total: 0,
				dataList: []
			}
		},
		mounted() {
		},
		watch: {
			queryParams: function () {
				this.pageNo = 1;
				this.getList();
			},
		},
		methods: {
			async getList() {
				this.loading = true;
				let result = await UserApi.authority.list(this.pageNo, this.pageSize, this.queryParams);
				this.loading = false;
				this.total = result.count || 0;
				this.dataList = result.data || [];
			}
		}
	};
</script>

<style scoped>

</style>
